.logo-social {
    height: 50px;
    width: 50px;
    align-content: center;
}

.logo-social-ins {
    height: 55px;
    width: 55px;
    align-content: center;
}


.footer {
    max-width: 100%;
    height: auto;
    text-align: center;
}