.fondo {
    color: white;
    background:
        radial-gradient(black 15%, transparent 16%) 0 0,
        radial-gradient(black 15%, transparent 16%) 8px 8px,
        radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
        radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
        background-color:#282828;
        background-size:16px 16px;
}

.img {
    max-width: 50%;
    height: auto;
    margin: 5em;
}